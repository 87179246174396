<template>
  <div>
    <el-upload
      class="upload-demo"
       action=""
       :auto-upload="false"
      
      :on-change="handleChange"
      multiple
      :limit="1"
      :file-list="fileList">
      <el-button type="primary" >第一步：导入excel数据</el-button>
    </el-upload>
    
    <el-button style="margin-top: 20px;margin-bottom: 20px;" type="primary" @click="demo">第二步：开始生成数据</el-button><br/>
    
    <div>总：{{  this.tableData.length?this.tableData.length-1 :0 }}行</div>
    <div>正在生成 {{  Math.floor(this.num/10) || 0  }} 张凭证</div>
    <el-button style="margin-top: 20px;margin-bottom: 50px;" type="primary" @click="abc">第三步：导出excel凭证</el-button>
    <!-- <el-table
      :data="tableData"
      style="width: 100%">
      <template v-for="(item,index) in listheader">
        <el-table-column
        :prop="`name`+index"
        :label="item"
        width="180">
      </el-table-column>
      </template>
      
    </el-table> -->
  </div>
</template>

<script>
import ExcelJS from "exceljs"
import FileSaver from "file-saver"
let workbook = null 
let  sheet1 = null

function getPropertyNameAndValue(obj) {
    const props = ['C10', 'C15', 'C20', 'C25', 'C30', 'C35', 'C40', 'C45', 'C50', 'C55', 'C60', '砂浆'];
    for (let prop of props) {
        if (obj.hasOwnProperty(prop)) {
            return { name: prop, value: obj[prop] };
        }
    }
    return { name: null, value: null }; // 如果没有找到任何属性，返回 { name: null, value: null }
}
export default {
  name: 'HelloWorld',
  data() {
    return {
      fileList: [],
      listheader:[],
      tableData:[],
      num:0,
      currentLine:1,
      fileName:''
    };
  },
  mounted(){
     // 创建工作簿
     workbook = new ExcelJS.Workbook();
    // 添加工作表，名为sheet1
    sheet1 = workbook.addWorksheet("sheet1");
    sheet1.pageSetup.paperSize = 9;    // A4打印
    sheet1.pageSetup.margins = {   // 打印页面居中，一页三张
        left: 0,
        right: 0,
        top: 0.2,
        bottom: 0,
        header: 0,
        footer: 0
    };
    sheet1.pageSetup.horizontalCentered = true;
    sheet1.pageSetup.verticalCentered = true;
    // 设置宽度
    sheet1.getColumn('A').width = 11;
    sheet1.getColumn('B').width = 30;
    sheet1.getColumn('C').width = 11;
    sheet1.getColumn('D').width = 30;
    sheet1.getColumn('E').width = 4.5;
  },
  methods:{
    async handleChange(file) {
      console.log(file)
       this.fileName = file.name
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);

        // 获取第一个工作表
        const worksheet = workbook.worksheets[0];

        // 从第三行开始遍历
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          console.log(rowNumber)
          // if(rowNumber == 2 ){
          //   this.listheader = row.values.slice(2, 26)
          //     return
          // }
          if (rowNumber >= 2) {
            // console.log(row.values.slice(2, 26))
            let obj = {};
            let temp= row.values.slice(2, 26)
            temp.map((item, index) => {
              let namelist =['客户','工程','日期','施工部位','C10','C15','C20','C25','C30','C35','C40','C45','C50','C55','C60','砂浆','合计','单价','砼金额','泵送费','已收金额','应收余额','备注','车牌']
              obj[namelist[index]] = item
            })
            // const objectArray = temp.map((item, index) => {
             
            this.tableData.push(obj)
              
            //   obj[`name`+index] = item;
            //   console.log('--------测试-------------')
            //   console.log(item)
            //  return obj;
            // });

          //  console.log(objectArray); 




            // console.log(row.values.slice(2, 26))
            // const tableData = rawData.map(row => ({
            //   name: row[0],
            //   age: row[1],
            //   address: row[2],
            // }));
           //this.tableData = row.values.slice(2, 26);
          }
        });
        console.log('------------数据源----------')
        console.log(this.tableData)
      };
      reader.readAsArrayBuffer(file.raw);
    },
    abc(){
        // 导出表格文件
        workbook.xlsx.writeBuffer().then((buffer) => {
          let file = new Blob([buffer], {type: "application/octet-stream"});
          FileSaver.saveAs(file, this.fileName);
        }).catch(error => console.log('Error writing excel export', error))
    },
    async demo(){
    // var i = 1;
      do {

        await new Promise(resolve => setTimeout(() => {
              this.exportExcel(this.num);
              this.currentLine += 1;
              resolve();
          }, 50));
          //  setTimeout(() => {
          //   this.exportExcel(this.num)
          //  this.currentLine = this.currentLine + 1
          //  }, 100);
         
      } while (this.currentLine<  this.tableData.length); // 
    },
    // handleChange(file,fileList){
    //   console.log('------- handleChange---------------------')
    //   console.log(file, fileList);
      
    // },
    // handleRemove(file, fileList) {
    //   console.log('-------handleRemove---------------------')
    //   console.log(file, fileList);
    // },
    // handlePreview(file) {
    //   console.log('-------on-preview------------')
    //   console.log(file);
    // },
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    // },
    // beforeRemove(file, fileList) {
    //   return this.$confirm(`确定移除 ${ file.name }？`);
    // },
    exportExcel(num){
      let tempnum = null
      if(num) {
         tempnum = num
       } else {
        tempnum = 0
       }

        // sheet1.pageSetup.printOptions.horizontalCentered = true;
        // sheet1.pageSetup.printOptions.verticalCentered = true; // 如果需要垂直居中 
       
      
        // 设置宽度

      
        // sheet1.eachRow({ includeEmpty: true }, (row) => {
        //   row.height = 27;
        // });
        sheet1.getRow(tempnum+1).height = 27;
        sheet1.getRow(tempnum+2).height = 27;
        sheet1.getRow(tempnum+3).height = 27;
        sheet1.getRow(tempnum+4).height = 27;
        sheet1.getRow(tempnum+5).height = 27;
        sheet1.getRow(tempnum+6).height = 27;
        sheet1.getRow(tempnum+7).height = 28;
        sheet1.getRow(tempnum+8).height = 28;
        sheet1.getRow(tempnum+9).height = 28;
        sheet1.getRow(tempnum+10).height = 27;
        // 合并A-D单元格
        sheet1.mergeCells(`A${tempnum+1}:D${tempnum+1}`);
        sheet1.mergeCells(`A${tempnum+2}:D${tempnum+2}`);
        sheet1.mergeCells(` E${tempnum+1}:E${tempnum+9}`) 
        sheet1.mergeCells(`A${tempnum+7}:D${tempnum+9}`);
        sheet1.mergeCells(`A${tempnum+10}:D${tempnum+10}`);
        // sheet1.mergeCells('A6:D6');
        // sheet1.mergeCells('A7:D7');
        // sheet1.mergeCells('A8:D8');
        //
        const firstRow1 = sheet1.getRow(tempnum+1);  
        firstRow1.font = {
          name: '方正小标宋简体', // 字体名称
          size: 16,      // 字体大小
          bold: false     // 加粗字体
        };
        firstRow1.alignment = {
          horizontal: 'center', // 水平居中
          vertical: 'middle'    // 垂直居中
        };



        const firstRow2 = sheet1.getRow(tempnum+2);  
        firstRow2.font = {
          name: '仿宋_GB2312', // 字体名称
          size: 11,      // 字体大小
          bold: false     // 加粗字体
        };
        firstRow2.alignment = {
          horizontal: 'left', // 水平居中
        };


        sheet1.getRow(tempnum+3).alignment = {
          horizontal: 'center', // 水平居中
          vertical: 'middle'    // 垂直居中
        };
        sheet1.getRow(tempnum+4).alignment = {
          horizontal: 'center', // 水平居中
          vertical: 'middle'    // 垂直居中
        };

        sheet1.getRow(tempnum+5).alignment = {
          horizontal: 'center', // 水平居中
          vertical: 'middle'    // 垂直居中
        };

        sheet1.getRow(tempnum+6).alignment = {
          horizontal: 'center', // 水平居中
          vertical: 'middle'    // 垂直居中
        };
        sheet1.getRow(tempnum+7).alignment = {
        vertical: 'top', // 垂直对齐方式：顶端对齐
        horizontal: 'left' // 水平对齐方式：左对齐
        };


        sheet1.getCell(`A${tempnum+3}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        sheet1.getCell(`A${tempnum+4}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        sheet1.getCell(`A${tempnum+5}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        sheet1.getCell(`A${tempnum+6}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        
        sheet1.getCell(`A${tempnum+7}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 10.8,      // 字体大小
        };

        sheet1.getCell(`D${tempnum+4}`).font = {
          name: '宋体', // 字体名称
          size: 10,      // 字体大小
        };

        sheet1.getCell(`B${tempnum+3}`).font = {
          name: '宋体', // 字体名称
          size: 10,      // 字体大小
        };

        sheet1.getCell(`D${tempnum+3}`).font = {
          name: '宋体', // 字体名称
          size: 10,      // 字体大小
        };

        sheet1.getCell(`D${tempnum+6}`).font ={
          name: '宋体', // 字体名称
          size: 10,      // 字体大小
        };

        sheet1.getCell(`C${tempnum+3}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        sheet1.getCell(`C${tempnum+4}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        sheet1.getCell(`C${tempnum+5}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };
        sheet1.getCell(`C${tempnum+6}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 14,      // 字体大小
        };

        sheet1.getCell(`E${tempnum+1}`).font = {
          name: '仿宋_GB2312', // 字体名称
          size: 12,      // 字体大小
        };
        // 设置合并单元格的值
        sheet1.getCell(`A${tempnum+1}`).value = '绵阳市腾晖建材有限公司商品混凝土发货（结算）凭证';
        
        sheet1.getCell(`A${tempnum+2}`).alignment = { wrapText: true, horizontal: 'left', vertical: 'top' };
        sheet1.getCell(`E${tempnum+1}`).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        sheet1.getCell(`A${tempnum+7}`).alignment = { wrapText: true,horizontal: 'left', vertical: 'top'};
        sheet1.getCell(`A${tempnum+2}`).value =`公司地址：绵阳市安州区盐井村2组\n联系电话：4622288    传真：4626318`

        sheet1.getCell(`A${tempnum+3}`).value =`客户名称`
        sheet1.getCell(`A${tempnum+4}`).value =`车牌号码`
        sheet1.getCell(`A${tempnum+5}`).value =`本车方量`
        sheet1.getCell(`A${tempnum+6}`).value =`发货日期`
        sheet1.getCell(`A${tempnum+7}`).value =`说明：1、混凝土供应根据国家规定、规范本公司出售条款所订。\n      2、混凝土到达施工现场后，客户须在90分钟内安排将车内混凝土卸出。如超出上述时\n         限卸货，本公司不能保证混凝土质量。\n      3、如客户逾时卸货，请先与本公司试验室或调度室联系，以便作出适当安排，保证混凝\n         土质量。\n      4、设计坍落度应与配合比报告相符。`
       
        sheet1.getCell(`B${tempnum+3}`).value = this.tableData[this.currentLine]['客户']  // 客户名称
        sheet1.getCell(`B${tempnum+3}`).alignment = { wrapText: true };

        sheet1.getCell(`D${tempnum+3}`).value = this.tableData[this.currentLine]['工程']  //工程名称
        sheet1.getCell(`D${tempnum+3}`).alignment = { wrapText: true };

        sheet1.getCell(`B${tempnum+4}`).value = this.tableData[this.currentLine]['车牌']  // 车票号码
        sheet1.getCell(`B${tempnum+4}`).alignment = { wrapText: true };

        sheet1.getCell(`D${tempnum+4}`).value = this.tableData[this.currentLine]['施工部位']  // 工程部位
        
        sheet1.getCell(`D${tempnum+4}`).alignment = { wrapText: true };

        let result = getPropertyNameAndValue(this.tableData[this.currentLine]);
        //{ name: 'C35', value: 'value35' }
       // console.log(result.);
        
        sheet1.getCell(`B${tempnum+5}`).value =result.value?result.value+'m³':''  // 本车方量

        sheet1.getCell(`D${tempnum+5}`).value = result.name  // 强度等级

        sheet1.getCell(`B${tempnum+6}`).value = this.tableData[this.currentLine]['日期']  // 发货时间

        sheet1.getCell(`D${tempnum+6}`).value = this.tableData[this.currentLine]['备注']  // 浇注方式
        sheet1.getCell(`D${tempnum+6}`).alignment = { wrapText: true };


        sheet1.getCell(`C${tempnum+3}`).value =`工程名称`

        
        sheet1.getCell(`C${tempnum+4}`).value =`工程部位`


        sheet1.getCell(`C${tempnum+5}`).value =`强度等级`
        sheet1.getCell(`C${tempnum+6}`).value =`浇注方式`


        //  sheet1.getCell(`E${tempnum+1}`).value =`第\n一\n联\n：\n存\n根\n联`
        
        
         for (let row = tempnum+3; row <= tempnum+9; row++) {
            for (let col = 1; col <= 4; col++) {
                const cell = sheet1.getCell(row, col);
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        }
        this.num = tempnum+10
      //  if(tempnum<100) {

      
      //  }
       
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
